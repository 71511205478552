import { graphql } from 'src/graphql';

export const Booking = graphql(`
  query Booking($id: ObjectID!) {
    unmoderatedBooking(_id: $id) {
      _id
      created
      updated
      name
      type
      status
      total_participants
      config {
        question {
          _id
          title
          description
        }
        options {
          review_submission
        }
        unmoderated {
          welcome_block {
            type
            title
            instructions
          }
          thank_you_block {
            type
            title
            instructions
          }
          task_blocks {
            _id
            type
            title
            instructions
            image
            image_path
            is_recording_enabled

            ... on TaskBlockFigmaPrototype {
              figma_prototype {
                file_id
                goal_screen_id
                start_screen_id
              }
            }

            ... on TaskBlockMultipleChoiceQuestion {
              multiple_choice_question {
                options {
                  _id
                  value
                }
                has_other_option
                is_multiple_select
                is_randomised_order
              }
            }

            ... on TaskBlockOpinionScale {
              opinion_scale {
                is_zero_start
                label_low
                label_mid
                label_high
                scale_max
                scale_type
              }
            }
          }
        }
      }
      recruit {
        participants
        device
        locations {
          type
          location {
            name
            city
            state
            country
            latitude
            longitude
            level
            timezone
            postal_code
            formatted_address
            google_location_types
            region
            google_location {
              place_id
              name
              address_components {
                long_name
                short_name
                types
              }
              geometry {
                latitude_ne
                longitude_ne
                latitude_sw
                longitude_sw
                width
                height
                radius_min
                radius_max
              }
              viewport {
                northeast {
                  lat
                  lng
                }
                southwest {
                  lat
                  lng
                }
              }
              map_polygon_id
            }
            currency_code
            currency_symbol
          }
        }
        age_range
        gender
        industry_and_occupation {
          _id
          name
          subcategories {
            _id
            name
          }
        }
        languages {
          locale
          fluency
        }
        education_level
        marital_status
        occupational_status
        business_ownership
        screener_questions {
          _id
          type
          title
          description
          is_multiple_selection
          options {
            _id
            label
            is_qualified
          }
        }
        participant_selection
        participants_excluded_months
        participant_source
        custom_terms {
          recipients
          _template_id
        }
      }
      contact {
        _primary_contact_id
      }
      listing_information {
        title
        description
      }
    }
  }
`);
