import { graphql } from 'src/graphql';

export const UpdateTaskBlockMultipleChoiceQuestion = graphql(`
  mutation UpdateTaskBlockMultipleChoiceQuestion($input: UpdateTaskBlockMultipleChoiceQuestionInput!) {
    updateTaskBlockMultipleChoiceQuestion(input: $input) {
      _id
      config {
        unmoderated {
          task_blocks {
            _id
            type
            title
            instructions
            image
            image_path
            is_recording_enabled

            ... on TaskBlockMultipleChoiceQuestion {
              multiple_choice_question {
                options {
                  _id
                  value
                }
                has_other_option
                is_multiple_select
                is_randomised_order
              }
            }
          }
        }
      }
    }
  }
`);
