import { Button } from '@askable/ui/core/button';
import { FormControl, FormItem, FormLabel } from '@askable/ui/core/form';
import { RadioGroup, RadioGroupItem } from '@askable/ui/core/radio-group';
import { cn } from '@askable/ui/lib/utils';
import { memo } from 'react';
import { useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormErrorMessage } from 'components/Form/FormErrorMessage';
import { FieldLabel } from 'containers/Studies/components/Fields/FieldLabel';

import type { InfoPopoverProps } from 'containers/Studies/components/InfoPopover';

interface Option {
  label: string;
  value: string;
  hasPreview?: boolean;
}

interface RadioGroupFieldProps {
  description?: string;
  infoPopover?: InfoPopoverProps;
  isDisabled?: boolean;
  label: string;
  name: string;
  options: Option[];
  previewLoadingId?: string | null;
  variant?: 'stacked' | 'inline' | 'inline-options';
  onPreview?: (value: string) => void;
}

const RadioGroupFieldComponent = ({
  description,
  infoPopover,
  isDisabled,
  label,
  name,
  options,
  previewLoadingId,
  variant = 'inline-options',
  onPreview,
}: RadioGroupFieldProps) => {
  const { t } = useTranslation();
  const {
    field,
    fieldState: { error },
  } = useController({ name });

  return (
    <FormItem className="flex flex-col">
      <div
        className={cn('flex flex-col gap-3', {
          'flex flex-col justify-between gap-2 sm:flex-row sm:items-center': variant === 'inline',
        })}
      >
        {label !== '' ? (
          <FieldLabel
            name={`${name}_${options[0].value}`}
            label={label}
            description={description}
            infoPopover={infoPopover}
          />
        ) : null}

        <>
          <FormControl>
            <RadioGroup
              {...field}
              aria-describedby={description ? `description_${name}` : undefined}
              className={cn('group flex flex-col flex-wrap gap-3 sm:flex-row', {
                'sm:flex-col': variant === 'stacked',
              })}
              defaultValue={field.value}
              disabled={isDisabled}
              id={`input_${name}`}
              onValueChange={field.onChange}
            >
              {options.map(option => (
                <FormLabel
                  key={option.value}
                  htmlFor={`input_${name}_${option.value}`}
                  className={cn(
                    `flex w-full flex-1 cursor-pointer items-center justify-between gap-2 whitespace-nowrap rounded-md border border-border
                    px-3 py-2 hover:border-input focus:border-input active:border-input`,
                    {
                      'border-primary ring-0.5 ring-primary hover:border-primary focus:border-primary active:border-primary':
                        field.value === option.value,
                      'cursor-not-allowed opacity-50 hover:border-border focus:border-border active:border-border':
                        isDisabled,
                      'sm:w-40': variant === 'inline',
                    },
                  )}
                >
                  <div className="flex items-center gap-2">
                    <RadioGroupItem
                      value={String(option.value)}
                      id={`input_${name}_${option.value}`}
                      disabled={isDisabled}
                    />
                    {option.label}
                  </div>

                  {option.hasPreview && onPreview ? (
                    <Button
                      variant="ghost"
                      className="-my-[5px] -mr-2 rounded-sm"
                      onClick={() => onPreview(option.value)}
                      isLoading={previewLoadingId === option.value}
                    >
                      {t('global.preview')}
                    </Button>
                  ) : null}
                </FormLabel>
              ))}
            </RadioGroup>
          </FormControl>
        </>
      </div>

      {error ? <FormErrorMessage message={error.message} prefix="sections.studies.formValidation" /> : null}
    </FormItem>
  );
};

RadioGroupFieldComponent.displayName = 'RadioGroupField';

export const RadioGroupField = memo(RadioGroupFieldComponent);
