import { AnchorButton } from '@askable/ui/core/button';
import { IconBlock } from '@askable/ui/unmod/icon-block';
import { InfoAlert } from '@askable/ui/unmod/info-alert';
import { useFeatureFlags } from 'feature-flags';
import { ChevronLeft } from 'lucide-react';
import { useTranslation } from 'react-i18next';

import { FigmaFileField } from 'containers/Studies/BuildStudy/components/Fields/FigmaFileField';
import { FigmaScreensField } from 'containers/Studies/BuildStudy/components/Fields/FigmaScreensField';
import { ImageField } from 'containers/Studies/BuildStudy/components/Fields/ImageField';
import { MultipleChoiceQuestionField } from 'containers/Studies/BuildStudy/components/Fields/MultipleChoiceQuestion/MultipleChoiceQuestionField';
import { OpinionScaleField } from 'containers/Studies/BuildStudy/components/Fields/OpinionScaleField';
import { useStudyContext } from 'containers/Studies/StudiesContainer';
import { InputField } from 'containers/Studies/components/Fields/InputField';
import { TextareaField } from 'containers/Studies/components/Fields/TextareaField';
import { StudySavingIndicator } from 'containers/Studies/components/StudySavingIndicator';

import { BlockActions } from '../components/BlockActions';
import { RecordField } from '../components/Fields/RecordField';

import type { ActiveBlock } from '../hooks/useActiveBlockId';
import type { blockSchema } from 'containers/Studies/data/schemas/blockSchema';
import type { z } from 'zod';

export type ValidatedFormValues = z.infer<typeof blockSchema>;

const imageWhitelist = [
  'figma_prototype',
  'multiple_choice_question',
  'opinion_scale',
  'open_answer',
  'context_screen',
];

const recordableWhitelist = ['figma_prototype'];

type Props = {
  activeBlock: NonNullable<ActiveBlock>;
  isDisabled?: boolean;
};

export const BlockEditor = ({ activeBlock, isDisabled }: Props) => {
  const { t } = useTranslation();
  const { UNMODERATED_STUDY_DEV } = useFeatureFlags(['UNMODERATED_STUDY_DEV']);
  const { study, isSaving } = useStudyContext();

  let screenWidth = 'desktop';
  if (typeof window !== 'undefined') {
    screenWidth = window.innerWidth < 1024 ? 'mobile' : 'desktop';
  }

  const isSystemBlock = ['welcome', 'thank_you'].includes(activeBlock.type);
  const id = '_id' in activeBlock ? activeBlock._id : activeBlock.type;
  const type = activeBlock.type;

  const titlePath = isSystemBlock
    ? 'sections.studies.build.formFields.title'
    : activeBlock.type === 'figma_prototype'
      ? 'sections.studies.build.formFields.taskTitle'
      : 'sections.studies.build.formFields.questionTitle';
  const instructionsPath = isSystemBlock
    ? 'sections.studies.build.formFields.instructions'
    : activeBlock.type === 'figma_prototype'
      ? 'sections.studies.build.formFields.taskInstructions'
      : 'sections.studies.build.formFields.questionInstructions';

  return (
    <main className="build-editor overflow-auto pb-16 lg:mx-0 lg:p-4" id="editor">
      <div className="flex min-h-full min-w-[20rem] flex-col gap-4 bg-background p-3 pt-0 lg:rounded-xl lg:p-5">
        <form className="flex flex-col gap-6">
          <header
            className="sticky top-0 -mx-3 flex items-center justify-between gap-2 !border-b-0.5 border-b border-border bg-background/90 px-3
              py-2 backdrop-blur-sm lg:static lg:m-0 lg:border-none lg:p-0"
          >
            <h2 className="break-word text-md flex items-center gap-2 overflow-hidden font-semibold leading-tight text-foreground lg:gap-3">
              <div className="lg:hidden">
                <AnchorButton size="icon" variant="ghost" href="#nav">
                  <ChevronLeft className="h-4 w-4" />
                </AnchorButton>
              </div>

              <IconBlock type={type} size={screenWidth === 'mobile' ? 'sm' : 'md'} />
              <div className="hidden lg:block">{t(`sections.studies.build.blocks.${type}.title`)}</div>
              <div className="truncate lg:hidden">{activeBlock.title}</div>
            </h2>

            <div className="flex items-center gap-2">
              <div className="px-1 lg:hidden">
                <StudySavingIndicator isSaving={isSaving} created={study.created} updated={study.updated} />
              </div>

              {!isSystemBlock ? <BlockActions blockId={id} /> : null}
            </div>
          </header>

          {isDisabled ? (
            <InfoAlert
              type="view-only"
              title={t('sections.studies.readOnlyTitle')}
              description={t('sections.studies.readOnlyDescription')}
            />
          ) : null}

          <InputField
            isDisabled={isDisabled}
            isDisabledSelectable
            name="title"
            label={t(`${titlePath}.label`)}
            placeholder={`${t(`${titlePath}.placeholder`)}...`}
          />

          <TextareaField
            name="instructions"
            isDisabled={isDisabled}
            isDisabledSelectable
            label={`${t(`${instructionsPath}.label`)} (${t('formFields.optional').toLocaleLowerCase()})`}
            placeholder={`${t(`${instructionsPath}.placeholder`)}...`}
          />

          {type === 'figma_prototype' ? (
            <>
              <FigmaFileField isDisabled={isDisabled} />
              <FigmaScreensField isDisabled={isDisabled} />
            </>
          ) : null}

          {type === 'multiple_choice_question' ? <MultipleChoiceQuestionField isDisabled={isDisabled} /> : null}
          {type === 'opinion_scale' ? <OpinionScaleField isDisabled={isDisabled} /> : null}

          {UNMODERATED_STUDY_DEV && imageWhitelist.includes(type) ? (
            <ImageField
              activeImage={'image' in activeBlock ? activeBlock.image : undefined}
              isDisabled={isDisabled}
              taskId={id}
            />
          ) : null}

          {recordableWhitelist.includes(type) ? (
            <>
              <hr />
              <RecordField isDisabled={isDisabled} />
            </>
          ) : null}
        </form>
      </div>
    </main>
  );
};
