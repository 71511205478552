import { Skeleton } from '@askable/ui/core/skeleton';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useQuery } from 'urql';

import { EmptyState } from 'containers/Studies/Results/components/EmptyState';
import { ParticipantHeader } from 'containers/Studies/Results/components/Participant/ParticipantHeader';
import { ParticipantResultItem } from 'containers/Studies/Results/components/Participant/ParticipantResultItem';
import { BookingSubmissionByUser } from 'containers/Studies/Results/data/BookingSubmissionByUser.query';
import { BookingTaskResults } from 'containers/Studies/Results/data/BookingTaskResults.query';
import { useStudyContext } from 'containers/Studies/StudiesContainer';
import { getPageTitle } from 'containers/Studies/utils/getPageTitle';
import { Sort } from 'generated/graphql';

import type {
  TaskBlockFigmaPrototype,
  TaskBlockMultipleChoiceQuestion,
  TaskBlockOpinionScale,
} from 'generated/graphql';

export const ParticipantContent = () => {
  const { t } = useTranslation();
  const { study } = useStudyContext();
  const params = useParams();
  const { studyId, participantId } = params;

  const [{ data: submissionData, fetching: isLoadingSubmission }] = useQuery({
    query: BookingSubmissionByUser,
    variables: {
      _booking_id: studyId ?? '',
      _user_id: participantId ?? '',
    },
    pause: !studyId || !participantId,
    requestPolicy: 'cache-first',
  });

  const [{ data, fetching }] = useQuery({
    query: BookingTaskResults,
    variables: {
      filter: {
        _booking_id: { eq: studyId ?? '' },
        _user_id: { eq: participantId ?? '' },
      },
      orderBy: { task_started: Sort.Asc },
    },
    pause: !studyId || !participantId,
  });

  const participant = submissionData?.bookingSubmissionByUser?.applicant;
  const participantName = participant
    ? [participant.firstname, participant.lastname].filter(Boolean).join(' ')
    : t('global.unknownUser');
  const results = data?.bookingTaskResults?.nodes;
  const duration = results?.length
    ? Math.max((results.at(-1)?.task_ended ?? 0) - (results.at(0)?.task_started ?? 0), 0)
    : 0;

  const getTaskBlock = (taskId: string) =>
    study.config?.unmoderated?.task_blocks?.find(block => block?._id === taskId) as
      | TaskBlockFigmaPrototype
      | TaskBlockMultipleChoiceQuestion
      | TaskBlockOpinionScale;

  return (
    <>
      <Helmet>
        <title>
          {getPageTitle({
            section: `${participantName} - ${t('sections.studies.tabs.results')}`,
            study: study.name || t('sections.studies.untitledStudy'),
          })}
        </title>
      </Helmet>

      <div
        id={`participant-${participantId}`}
        className="flex h-full flex-col gap-10 rounded-xl bg-background p-3 lg:p-6"
      >
        {!isLoadingSubmission && participant ? (
          <ParticipantHeader
            name={participantName}
            age={participant.age ?? undefined}
            city={participant.city ?? undefined}
            country={typeof participant.country === 'string' ? participant.country : undefined}
            gender={participant.gender ?? undefined}
            duration={duration}
          />
        ) : null}

        {isLoadingSubmission && !participant ? <Skeleton className="h-12 w-full" /> : null}

        <section className="flex flex-col gap-6" id="participant-responses">
          {!fetching && results ? (
            <>
              {(results?.length ?? 0) > 0 ? (
                <ol className="flex flex-col gap-6 text-pretty">
                  {results?.map(result => (
                    <ParticipantResultItem
                      key={result._id}
                      {...result}
                      taskBlock={getTaskBlock(result._task_id) ?? { title: t('global.untitledTask') }}
                    />
                  ))}
                </ol>
              ) : (
                <EmptyState />
              )}
            </>
          ) : (
            <>
              <Skeleton className="h-12 w-full" />
              <Skeleton className="h-12 w-full" />
            </>
          )}
        </section>
      </div>
    </>
  );
};
