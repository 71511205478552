import { graphql } from 'src/graphql';

export const DeleteTaskBlock = graphql(`
  mutation DeleteTaskBlock($input: DeleteTaskBlockInput!) {
    deleteTaskBlock(input: $input) {
      _id
      config {
        unmoderated {
          task_blocks {
            _id
            type
            title
            instructions
            image
            is_recording_enabled

            ... on TaskBlockFigmaPrototype {
              figma_prototype {
                file_id
                goal_screen_id
                start_screen_id
              }
            }
          }
        }
      }
    }
  }
`);
