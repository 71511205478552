import { Button } from '@askable/ui/core/button';
import { Textarea } from '@askable/ui/core/textarea';
import { cn } from '@askable/ui/lib/utils';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { GripVertical, Trash2 } from 'lucide-react';
import { useTranslation } from 'react-i18next';

import type { CSSProperties } from 'react';

interface MultipleChoiceQuestionOptionProps {
  id: string;
  index: number;
  isDisabled?: boolean;
  isFocused?: boolean;
  isMovable?: boolean;
  value: string;
  onChangeValue: (index: number, value: string) => void;
  onRemove: (index: number) => void;
}

export const MultipleChoiceQuestionOption = ({
  id,
  index,
  isDisabled,
  isFocused,
  isMovable,
  value,
  onChangeValue,
  onRemove,
}: MultipleChoiceQuestionOptionProps) => {
  const { t } = useTranslation();
  const { attributes, isDragging, listeners, setNodeRef, transform, transition } = useSortable({
    id: isMovable && !isDisabled ? id : '',
  });

  const dragStyle: CSSProperties = {
    transform: CSS.Translate.toString(transform),
    transition,
  };

  return (
    <div
      ref={setNodeRef}
      style={dragStyle}
      className={cn(
        'group flex items-start gap-2 rounded-md bg-background opacity-100 transition-opacity starting:opacity-0',
        {
          'z-20 shadow-lg': isDragging,
        },
      )}
    >
      <div
        className="z-10 -mr-10 !cursor-grab touch-none px-2 py-[11px] active:!cursor-grabbing aria-[disabled=true]:!cursor-default
          aria-[disabled=true]:text-input"
        {...attributes}
        {...listeners}
        aria-disabled={!isMovable || isDisabled}
      >
        <GripVertical className="h-4 w-4 text-muted-foreground" />
      </div>

      <div className="flex flex-1 flex-col gap-1">
        <label htmlFor={`input_multiple_choice_question_${index}_option_value`} className="sr-only">
          {t('sections.studies.answers', { count: 1 })} {index + 1}
        </label>
        <Textarea
          autoFocus={isFocused}
          value={value}
          name={`input_multiple_choice_question_${index}_option_value`}
          disabled={isDisabled}
          rows={1}
          maxRows={4}
          onChange={e => onChangeValue(index, e.target.value)}
          placeholder={`${t('sections.studies.build.formFields.multipleChoiceQuestion.answerPlaceholder')}`}
          className="pl-8 pr-8"
        />
      </div>

      <Button
        variant="ghost"
        size="icon"
        title={t('global.remove')}
        className="hover-none:opacity-100 -ml-11 mt-[3px] rounded-sm opacity-0 transition-all disabled:opacity-0
          group-focus-within:opacity-100 disabled:group-focus-within:opacity-0 group-hover:opacity-100
          disabled:group-hover:opacity-0"
        disabled={isDisabled || !isMovable}
        onClick={() => onRemove(index)}
      >
        <Trash2 className="h-4 w-4" />
      </Button>
    </div>
  );
};
