export const MAX_FILE_SIZE = 2 * 1024 * 1024;
export const ACCEPTED_MIME_TYPES = ['image/jpeg', 'image/png', 'image/jpg', 'image/webp'] as const;
export const UPLOAD_MIME_TYPES = ['image_jpg', 'image_png', 'image_webp'] as const;

export type AvailableMimeType = (typeof ACCEPTED_MIME_TYPES)[number];
export type UploadMimeType = (typeof UPLOAD_MIME_TYPES)[number];
export const MIME_TYPE_MAP: Record<AvailableMimeType, UploadMimeType> = {
  'image/jpeg': 'image_jpg',
  'image/png': 'image_png',
  'image/jpg': 'image_jpg',
  'image/webp': 'image_webp',
};

export type UploadImageContextType = {
  error: string | null;
  loading: boolean;
  setError: (error: string) => void;
  setLoading: (loading: boolean) => void;
  onUploadImageComplete: (url: string) => Promise<void> | void;
};

export type UploadImageContextProps = {
  onError: (error: string) => void;
  onLoading: (loading: boolean) => void;
};

export type UploadImageResultType = {
  path: string;
  putFileUrl: string;
  url: string;
};
