import { Button } from '@askable/ui/core/button';
import { FormControl, FormField, FormItem, FormLabel } from '@askable/ui/core/form';
import { Switch } from '@askable/ui/core/switch';
import { cn } from '@askable/ui/lib/utils';
import { Image, Trash2, Loader2 } from 'lucide-react';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useUploadImage, MAX_FILE_SIZE } from 'shared-utils/hooks/useUploadImage';
import { useMutation } from 'urql';

import { FormErrorMessage } from 'components/Form/FormErrorMessage';
import { UploadImage } from 'containers/Studies/BuildStudy/data/UploadImage.mutation';
import { UploadArea } from 'generated/graphql';

import type { BlockFormFields } from 'containers/Studies/BuildStudy/containers/BlockForm';
import type { DragEvent, ChangeEvent } from 'react';
import type { UploadMimeType } from 'shared-utils/hooks/useUploadImage';

interface ImageFieldProps {
  activeImage?: string | null;
  isDisabled?: boolean;
  taskId: string;
}

interface ImageUploadProps {
  activeImage?: string | null;
  accept?: string;
  error: string | null;
  isLoading: boolean;
  onImageSelect: (image: File | null) => void;
}

interface ImagePreview {
  name: string;
  size: number | null;
}
const extractFilenamePath = (url: string) => {
  try {
    const urlObj = new URL(url);
    const pathname = urlObj.pathname;
    return pathname.split('/').pop();
  } catch (error) {
    console.error(error);
    return 'image';
  }
};

export const ImageUploadField = ({
  activeImage,
  accept = '.png,.jpg,.jpeg,.gif',
  error,
  isLoading,
  onImageSelect,
}: ImageUploadProps) => {
  const { t } = useTranslation();

  const [isDragging, setIsDragging] = useState(false);
  const [validationError, setValidationError] = useState('');
  const [selectedImage, setSelectedImage] = useState<ImagePreview | null>(
    activeImage
      ? {
          name: extractFilenamePath(activeImage) || 'image.png',
          size: null,
        }
      : null,
  );

  const handleFile = (image: File) => {
    setValidationError('');

    const imageExtension = image.name.split('.').pop()?.toLowerCase();
    const acceptedTypes = accept.split(',').map(type => type.trim().toLowerCase().replace('.', ''));

    if (!imageExtension || !acceptedTypes.includes(imageExtension)) {
      setValidationError(t('image.wrongFormat'));
      return;
    }

    if (image.size > MAX_FILE_SIZE) {
      setValidationError(t('image.tooLarge'));
      return;
    }

    setSelectedImage({
      name: image.name,
      size: image.size,
    });
    onImageSelect(image);
  };

  const handleDrop = (e: DragEvent<HTMLLabelElement>) => {
    e.preventDefault();
    setIsDragging(false);

    if (!e.dataTransfer.files.length) {
      return;
    }

    const image = e.dataTransfer.files[0];
    if (image) {
      handleFile(image);
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const image = e.target.files?.[0];
    if (image) {
      handleFile(image);
    }
  };

  const removeFile = (e: React.MouseEvent) => {
    e.stopPropagation();
    setSelectedImage(null);
    onImageSelect(null);
  };

  return (
    <div className="flex flex-col gap-2">
      {selectedImage ? (
        <div className="border-1 rounded-lg border-border bg-secondary p-4">
          <div className="flex items-center justify-between gap-2">
            <div className="flex items-center gap-2 text-sm">
              {isLoading ? <Loader2 className="h-6 w-6 animate-spin" /> : null}

              {!isLoading && activeImage ? (
                <div className="h-11 w-11 rounded-sm">
                  <img src={activeImage} height="44" className="m-auto h-11 w-auto" />
                </div>
              ) : null}

              <div className="flex flex-col">
                <div className="font-semibold">{selectedImage.name}</div>
                {selectedImage.size ? (
                  <div className="text-sm text-muted-foreground">
                    {parseFloat((selectedImage.size / 1024).toFixed(1))}KB
                  </div>
                ) : null}
              </div>
            </div>

            {!isLoading ? (
              <Button onClick={removeFile} variant="ghost" size="icon" aria-label={t('global.remove')}>
                <Trash2 className="h-4 w-4" />
              </Button>
            ) : null}
          </div>
        </div>
      ) : (
        <label
          className={cn(
            'relative block cursor-pointer rounded-lg border-2 border-dashed border-border p-4 transition-colors hover:border-primary',
            { 'border-primary': isDragging },
          )}
          onDragEnter={e => {
            e.preventDefault();
            setIsDragging(true);
          }}
          onDragOver={e => {
            e.preventDefault();
          }}
          onDragLeave={e => {
            e.preventDefault();
            setIsDragging(false);
          }}
          onDrop={handleDrop}
        >
          <div className="pointer-events-none flex items-center gap-2">
            <Image className={cn('h-6 w-6 text-muted-foreground', { 'text-foreground': isDragging })} />

            <div className="flex flex-col">
              <label htmlFor="input_image_upload" className="text-sm font-medium">
                {isDragging
                  ? t('sections.studies.build.formFields.image.uploadTitleDrop')
                  : t('sections.studies.build.formFields.image.uploadTitle')}
              </label>
              <span className="text-xs text-muted-foreground">
                {t('sections.studies.build.formFields.image.uploadDescription', {
                  types: accept.toUpperCase().replace(/\./g, ' '),
                  size: (MAX_FILE_SIZE / (1024 * 1024)).toFixed(0),
                })}
              </span>
            </div>
          </div>
          <input type="file" className="hidden" id="input_image_upload" accept={accept} onChange={handleChange} />
        </label>
      )}

      {validationError ? <FormErrorMessage message={validationError} prefix="formValidation" /> : null}
      {error && !validationError ? <FormErrorMessage message="uploadFailed" prefix="formValidation" /> : null}
    </div>
  );
};

export const ImageField = ({ taskId, isDisabled, activeImage }: ImageFieldProps) => {
  const { t } = useTranslation();
  const { control, setValue } = useFormContext<BlockFormFields>();
  const [isChecked, setIsChecked] = useState(false);

  const [, createUpload] = useMutation(UploadImage);

  const getPresignedURL = async (mimeType: UploadMimeType) => {
    const response = await createUpload({
      input: { area: UploadArea.BookingTasks, area_id: taskId, mime_type: mimeType },
    });

    if (response.error) {
      return Promise.reject(response.error);
    }

    const path = response.data?.createUpload.upload.path;
    const url = response.data?.createUpload.upload.url;
    const putFileUrl = response.data?.createUpload.put_file_url;

    if (!url || !putFileUrl || !path) {
      return Promise.reject(t('formValidation.image.failed'));
    }

    return { path, url, putFileUrl };
  };

  const { upload, loading, error } = useUploadImage(getPresignedURL);

  const handleImageSelect = async (image: File | null) => {
    if (!image) {
      setValue('image_path', null);
      setIsChecked(false);
      return;
    }

    const { path } = await upload(image);

    if (path) {
      setValue('image_path', path);
    }
  };

  return (
    <FormField
      control={control}
      name="image_path"
      render={({ field }) => (
        <FormItem>
          <div className="relative flex items-center justify-between gap-2">
            <FormLabel className="flex w-fit items-center gap-1">
              {t('sections.studies.build.formFields.image.label')}
            </FormLabel>
            <FormControl>
              <Switch
                aria-describedby="record-description"
                checked={!!field.value || isChecked}
                disabled={isDisabled}
                onCheckedChange={setIsChecked}
                size="small"
              />
            </FormControl>
          </div>

          <div className="-mt-[4px] text-pretty text-xs text-muted-foreground" id="record-description">
            {t('sections.studies.build.formFields.image.description')}
          </div>

          {field.value || isChecked ? (
            <ImageUploadField
              activeImage={activeImage}
              onImageSelect={handleImageSelect}
              isLoading={loading}
              error={error}
            />
          ) : null}
        </FormItem>
      )}
    />
  );
};
