import { graphql } from 'src/graphql';

export const UpdateTaskBlockOpinionScale = graphql(`
  mutation UpdateTaskBlockOpinionScale($input: UpdateTaskBlockOpinionScaleInput!) {
    updateTaskBlockOpinionScale(input: $input) {
      _id
      config {
        unmoderated {
          task_blocks {
            _id
            type
            title
            instructions
            image
            image_path
            is_recording_enabled

            ... on TaskBlockOpinionScale {
              opinion_scale {
                is_zero_start
                label_low
                label_mid
                label_high
                scale_max
                scale_type
              }
            }
          }
        }
      }
    }
  }
`);
