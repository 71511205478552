import { tryit } from 'radash';
import { useState } from 'react';
import { z } from 'zod';

import { ACCEPTED_MIME_TYPES, MAX_FILE_SIZE, MIME_TYPE_MAP } from './types';

import type { UploadMimeType, UploadImageResultType } from './types';

/**
 * Schema for validating the file
 */
const fileSchema = z.object({
  type: z.enum(ACCEPTED_MIME_TYPES, { errorMap: () => ({ message: 'Invalid file type' }) }),
  size: z.number().max(MAX_FILE_SIZE, 'File size is too large'),
});

/**
 * Uploads a file to the S3 presigned URL
 */
async function putFile(url: string, file: File) {
  return fetch(url, {
    method: 'PUT',
    body: file,
    headers: {
      'Content-Type': file.type,
    },
  }).then(res => {
    if (res.ok) {
      return res.text();
    }
    throw new Error('Failed to upload');
  });
}

export function useUploadImage(getPresignedURL: (mimeType: UploadMimeType) => Promise<UploadImageResultType>) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const upload = async (file: File) => {
    const validationResult = fileSchema.safeParse({ type: file.type, size: file.size });
    if (!validationResult.success) {
      setError(validationResult.error.errors[0].message);
      return {
        error: validationResult.error.errors[0].message,
      };
    }

    setLoading(true);
    const updateMimeType = MIME_TYPE_MAP[validationResult.data.type];
    const [getPresignedURLError, result] = await tryit(getPresignedURL)(updateMimeType);
    if (getPresignedURLError) {
      setLoading(false);
      setError('Failed to upload');
      return {
        error: 'Failed to upload',
      };
    }

    const [err] = await tryit(putFile)(result.putFileUrl, file);
    if (err) {
      setLoading(false);
      setError('Failed to upload');
      return {
        error: 'Failed to upload',
      };
    }
    setLoading(false);

    return {
      url: result.url,
      path: result.path,
    };
  };

  return {
    upload,
    loading,
    error,
  };
}

export * from './types';
