import { graphql } from 'src/graphql';

export const OpinionScaleResult = graphql(`
  query OpinionScaleResult($_booking_id: ID!, $_task_id: ID!) {
    bookingTaskResultOpinionScale(_booking_id: $_booking_id, _task_id: $_task_id) {
      average_rating
      median_rating
      rating_counts {
        count
        rating
      }
      scale_type
      total_ratings
      total_responses
    }
  }
`);
