import { Button } from '@askable/ui/core/button';
import { Table, TableBody, TableHead, TableHeader, TableRow } from '@askable/ui/core/table';
import { useEffect, useRef, useState, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';
import { match } from 'ts-pattern';
import { useQuery } from 'urql';

import { EmptyState } from 'containers/Studies/Results/components/EmptyState';
import { Section } from 'containers/Studies/Results/components/Section';
import { BookingTaskResults } from 'containers/Studies/Results/data/BookingTaskResults.query';
import { useStudyContext } from 'containers/Studies/StudiesContainer';

import { FigmaPrototypeResponseTableRow } from './FigmaPrototype/FigmaPrototypeResponseTableRow';
import { MultipleChoiceQuestionResponseTableRow } from './MultipleChoiceQuestion/MultipleChoiceQuestionResponseTableRow';
import { OpinionScaleResponseTableRow } from './OpinionScale/OpinionScaleResponseTableRow';

import type { BookingTaskResultPartial } from 'containers/Studies/Results/data/BookingTaskResults.query';

const PER_PAGE = 10;
interface IndividualResponsesProps {
  type: BookingTaskResultPartial['details']['type'];
}

const FigmaPrototypeTableHeader = () => {
  const { t } = useTranslation();

  return (
    <>
      <TableHead className="min-w-40">{t('sections.studies.results.participants', { count: 1 })}</TableHead>
      <TableHead>{t('sections.studies.results.outcome')}</TableHead>
      <TableHead>{t('sections.studies.results.duration')}</TableHead>
      <TableHead>{t('sections.studies.results.misclickRate')}</TableHead>
      <TableHead>{t('sections.studies.results.screen.viewed')}</TableHead>
      <TableHead>{t('sections.studies.results.completionDate')}</TableHead>
    </>
  );
};

const MultipleChoiceQuestionTableHeader = () => {
  const { t } = useTranslation();

  return (
    <>
      <TableHead>{t('sections.studies.results.participants', { count: 1 })}</TableHead>
      <TableHead className="w-full min-w-40">{t('sections.studies.answers', { count: 1 })}</TableHead>
      <TableHead>{t('sections.studies.results.completionDate')}</TableHead>
    </>
  );
};

const OpinionScaleTableHeader = () => {
  const { t } = useTranslation();

  return (
    <>
      <TableHead className="flex-1">{t('sections.studies.results.participants', { count: 1 })}</TableHead>
      <TableHead className="w-1/4">{t('sections.studies.results.rating')}</TableHead>
      <TableHead className="w-1/4">{t('sections.studies.results.completionDate')}</TableHead>
    </>
  );
};

export const IndividualResponses = ({ type }: IndividualResponsesProps) => {
  const { t } = useTranslation();
  const { study } = useStudyContext();
  const [searchParams] = useSearchParams();
  const params = useParams();
  const { taskId } = params;
  const lastRowRef = useRef<HTMLTableRowElement>(null);
  const [cursor, setCursor] = useState<string | null>(null);

  const [{ data, fetching }] = useQuery({
    query: BookingTaskResults,
    variables: {
      first: Number(searchParams.get('limit') ?? PER_PAGE),
      after: cursor ?? undefined,
      filter: {
        _booking_id: { eq: study._id },
        _task_id: { eq: taskId },
        // TODO: we actually want to filter by booking submission status here, but that's not possible / implemented
        // yet, so for now we just filter out started and loaded results
        status: { in: ['completed', 'skipped'] },
      },
    },
    pause: !taskId,
  });

  const results = data?.bookingTaskResults.nodes ?? [];
  const moreCount =
    (data?.bookingTaskResults.totalCount ?? 0) > results.length
      ? (data?.bookingTaskResults.totalCount ?? 0) - results.length
      : 0;
  const description =
    type === 'figma_prototype' ? 'individualResponsesDescriptionFigmaPrototype' : 'individualResponsesDescription';

  // Scroll to the last row when new results are loaded
  useEffect(() => {
    if (results.length > PER_PAGE && lastRowRef.current) {
      lastRowRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, [results.length]);

  const handleLoadMore = async () => {
    if (data?.bookingTaskResults.pageInfo.hasNextPage && data?.bookingTaskResults.pageInfo.endCursor) {
      setCursor(data?.bookingTaskResults.pageInfo.endCursor);
    }
  };

  return (
    <Section
      description={t(`sections.studies.results.${description}`)}
      id="individual-responses"
      title={t('sections.studies.results.individualResponsesTitle')}
    >
      {results && results.length > 0 ? (
        <Table>
          <TableHeader>
            <TableRow className="whitespace-nowrap">
              {match(type)
                .with('figma_prototype', () => <FigmaPrototypeTableHeader />)
                .with('multiple_choice_question', () => <MultipleChoiceQuestionTableHeader />)
                .with('opinion_scale', () => <OpinionScaleTableHeader />)
                .otherwise(() => null)}
            </TableRow>
          </TableHeader>

          <TableBody>
            {results.map((result, index) => (
              <Fragment key={result._id}>
                {match(type)
                  .with('figma_prototype', () => (
                    <FigmaPrototypeResponseTableRow
                      result={result}
                      ref={index === results.length - 1 ? lastRowRef : undefined}
                    />
                  ))
                  .with('multiple_choice_question', () => (
                    <MultipleChoiceQuestionResponseTableRow
                      result={result}
                      ref={index === results.length - 1 ? lastRowRef : undefined}
                    />
                  ))
                  .with('opinion_scale', () => (
                    <OpinionScaleResponseTableRow
                      result={result}
                      ref={index === results.length - 1 ? lastRowRef : undefined}
                    />
                  ))
                  .otherwise(() => null)}
              </Fragment>
            ))}
          </TableBody>
        </Table>
      ) : (
        <EmptyState />
      )}

      {moreCount ? (
        <Button variant="ghost" className="w-full" onClick={handleLoadMore} isLoading={fetching}>
          {t('global.plusMore', { count: moreCount })}
        </Button>
      ) : null}
    </Section>
  );
};
