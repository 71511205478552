import { Button } from '@askable/ui/core/button';
import { cn } from '@askable/ui/lib/utils';
import { Figma } from '@askable/ui/unmod/icons';
import { MultipleChoiceQuestion } from '@askable/ui/unmod/multiple-choice-question';
import { OpinionScale } from '@askable/ui/unmod/opinion-scale';
import { PermissionsList } from '@askable/ui/unmod/permissions-list';
import { TaskCard, TaskCardHeader } from '@askable/ui/unmod/task-card';
import { TaskCardDraggable, TaskCardDraggableContent } from '@askable/ui/unmod/task-card-draggable';
import { ArrowRight } from 'lucide-react';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { match } from 'ts-pattern';

import { useBlockContext } from 'containers/Studies/BuildStudy/BuildStudy';
import { SelectStartScreen } from 'containers/Studies/BuildStudy/components/Fields/FigmaScreensField/SelectStartScreen';
import { useStudyContext } from 'containers/Studies/StudiesContainer';
import { FigmaPreview } from 'containers/Studies/components/FigmaPreview';
import { SystemBlockType, TaskBlockType } from 'generated/graphql';

import type { ActiveBlock } from '../hooks/useActiveBlockId';
import type { ReactNode } from 'react';

interface BlockPreviewProps {
  children?: ReactNode;
  activeBlock: NonNullable<ActiveBlock>;
  activeBlockId: string;
}

export const BlockPreview = ({ activeBlock, activeBlockId }: BlockPreviewProps) => {
  const { t } = useTranslation();
  const { screenSelection, setScreenSelection, isCardCollapsed, setIsCardCollapsed } = useBlockContext();
  const { study, setActiveSection } = useStudyContext();
  const [isGoalScreen, setIsGoalScreen] = useState(false);

  const isRecordingEnabled =
    (activeBlock.type === 'welcome' &&
      study.config?.unmoderated?.task_blocks?.some(task => task?.is_recording_enabled)) ??
    false;

  useEffect(() => {
    setScreenSelection(null);
    setIsCardCollapsed(false);
  }, [setScreenSelection, setIsCardCollapsed, activeBlockId]);

  const handleSelectedNode = (nodeId: string) => {
    const goalScreenReached =
      'figma_prototype' in activeBlock && nodeId === activeBlock.figma_prototype?.goal_screen_id;
    setIsGoalScreen(goalScreenReached);
    if (goalScreenReached) {
      setIsCardCollapsed(false);
    }
  };

  return (
    <>
      <aside
        className={cn('build-preview h-full overflow-hidden', {
          'touch-events-none absolute right-0 opacity-0': screenSelection?.mode === 'start',
        })}
        id="preview"
      >
        <div className="flex h-full flex-col bg-secondary p-3 pt-2 transition-transform @container lg:p-8">
          {match(activeBlock.type)
            .with(SystemBlockType.Welcome, SystemBlockType.ThankYou, () => (
              <TaskCard
                id={activeBlockId}
                action={
                  activeBlock.type === SystemBlockType.Welcome ? (
                    <Button variant="primary" size="lg" disabled>
                      {t('sections.studies.getStarted')}
                      <ArrowRight className="block h-4 w-4" />
                    </Button>
                  ) : (
                    <Button variant="primary" size="lg" disabled>
                      {t('sections.studies.backToAskable')}
                    </Button>
                  )
                }
              >
                <TaskCardHeader
                  instructions={activeBlock.instructions}
                  title={activeBlock.title}
                  type={activeBlock.type}
                />
                {isRecordingEnabled ? (
                  <div className="px-4 md:px-8">
                    <PermissionsList
                      description={t('sections.studies.permissions.description')}
                      permissions={[
                        { key: 'camera', description: t('sections.studies.permissions.camera') },
                        {
                          key: 'microphone',
                          description: t('sections.studies.permissions.microphone'),
                        },
                      ]}
                    />
                  </div>
                ) : null}
              </TaskCard>
            ))
            .with(TaskBlockType.FigmaPrototype, () => (
              <>
                {'figma_prototype' in activeBlock && !activeBlock.figma_prototype?.file_id ? (
                  <div className="drag-container relative h-full w-full @container">
                    <div className="h-full w-full rounded-xl bg-prototype p-6">
                      <div className="flex h-full w-full items-center justify-center">
                        <div className="flex flex-col items-center gap-3 text-center font-semibold opacity-50">
                          <Figma size={48} />
                          {t('sections.studies.previewNoPrototype')}
                        </div>
                      </div>

                      <TaskCardDraggable
                        collapseLabel={t('sections.studies.hideInstructions')}
                        id={activeBlockId}
                        isCollapsed={isCardCollapsed}
                        isDisabled
                        isDraggable
                        isPositionSaved={false}
                        setIsCollapsed={setIsCardCollapsed}
                        title={t('sections.studies.tasks', { count: 1 })}
                        action={
                          <Button variant="outline" size="lg" disabled>
                            {t('sections.studies.endTask')}
                          </Button>
                        }
                      >
                        <TaskCardDraggableContent instructions={activeBlock.instructions} title={activeBlock.title} />
                      </TaskCardDraggable>
                    </div>
                  </div>
                ) : null}

                {'figma_prototype' in activeBlock && activeBlock.figma_prototype?.file_id ? (
                  <FigmaPreview
                    activeBlockId={activeBlockId}
                    fileId={activeBlock.figma_prototype.file_id}
                    startScreenId={activeBlock.figma_prototype.start_screen_id ?? undefined}
                    submitGoalScreenId={nodeId => {
                      screenSelection?.onSave(nodeId);
                      setActiveSection('editor');
                    }}
                    showGoalScreenSelect={screenSelection?.mode === 'goal'}
                    cancelGoalScreenSelect={() => {
                      setScreenSelection(null);
                      setActiveSection('editor');
                    }}
                    onSelectedNode={handleSelectedNode}
                  >
                    {screenSelection?.mode !== 'goal' ? (
                      <TaskCardDraggable
                        id={activeBlockId}
                        isDisabled
                        isDraggable={!isGoalScreen}
                        title={t('sections.studies.tasks', { count: 1 })}
                        isCollapsed={isCardCollapsed}
                        setIsCollapsed={setIsCardCollapsed}
                        collapseLabel={!isGoalScreen ? t('sections.studies.hideInstructions') : undefined}
                        isPositionSaved={false}
                        action={
                          <Button variant="outline" size="lg" disabled>
                            {t('sections.studies.endTask')}
                          </Button>
                        }
                      >
                        <TaskCardDraggableContent
                          instructions={
                            isGoalScreen ? t('sections.studies.taskSuccessDescription') : activeBlock.instructions
                          }
                          title={isGoalScreen ? t('sections.studies.taskSuccessTitle') : activeBlock.title}
                        />
                      </TaskCardDraggable>
                    ) : null}
                  </FigmaPreview>
                ) : null}
              </>
            ))
            .with(TaskBlockType.MultipleChoiceQuestion, () => {
              return (
                <TaskCard
                  id={activeBlockId}
                  action={
                    <Button variant="primary" size="lg" disabled>
                      {t('global.continue')}
                      <ArrowRight className="block h-4 w-4" />
                    </Button>
                  }
                >
                  <TaskCardHeader
                    instructions={activeBlock.instructions}
                    image={'image' in activeBlock ? activeBlock.image : null}
                    subtitle={t('global.questions', { count: 1 })}
                    title={activeBlock.title}
                    type={activeBlock.type}
                  />

                  {'multiple_choice_question' in activeBlock ? (
                    <div className="m-auto w-full flex-1 p-4 md:px-8">
                      <MultipleChoiceQuestion
                        hasOtherOption={activeBlock.multiple_choice_question?.has_other_option ?? false}
                        isMultipleSelect={activeBlock.multiple_choice_question?.is_multiple_select ?? false}
                        isRandomisedOrder={activeBlock.multiple_choice_question?.is_randomised_order ?? false}
                        labels={{
                          input: t('components.multipleChoiceQuestion.input'),
                          other: t('components.multipleChoiceQuestion.other'),
                        }}
                        options={activeBlock.multiple_choice_question?.options ?? []}
                        onChange={() => {}}
                      />
                    </div>
                  ) : null}
                </TaskCard>
              );
            })
            .with(TaskBlockType.OpinionScale, () => {
              return (
                <TaskCard
                  id={activeBlockId}
                  action={
                    <Button variant="primary" size="lg" disabled>
                      {t('global.continue')}
                      <ArrowRight className="block h-4 w-4" />
                    </Button>
                  }
                >
                  <TaskCardHeader
                    instructions={activeBlock.instructions}
                    image={'image' in activeBlock ? activeBlock.image : null}
                    subtitle={t('global.questions', { count: 1 })}
                    title={activeBlock.title}
                    type={activeBlock.type}
                  />

                  {'opinion_scale' in activeBlock ? (
                    <div className="m-auto w-full flex-1 p-4 md:px-8">
                      <OpinionScale
                        isZeroStart={activeBlock.opinion_scale?.is_zero_start ?? false}
                        labelLow={activeBlock.opinion_scale?.label_low ?? ''}
                        labelMid={activeBlock.opinion_scale?.label_mid ?? ''}
                        labelHigh={activeBlock.opinion_scale?.label_high ?? ''}
                        max={activeBlock.opinion_scale?.scale_max ?? 5}
                        optionLabel={t('sections.studies.options', { count: 1 })}
                        type={activeBlock.opinion_scale?.scale_type ?? 'numerical'}
                        onChange={() => {}}
                      />
                    </div>
                  ) : null}
                </TaskCard>
              );
            })

            .exhaustive()}
        </div>
      </aside>

      {/* TODO: render goal selection here in the same way, to decouple it from the preview screen */}
      {screenSelection?.mode === 'start' && 'figma_prototype' in activeBlock ? (
        <SelectStartScreen
          fileId={activeBlock.figma_prototype?.file_id}
          activeStartScreenId={activeBlock.figma_prototype?.start_screen_id ?? null}
          onChange={nodeId => screenSelection?.onSave(nodeId)}
        />
      ) : null}
    </>
  );
};
