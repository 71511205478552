import { graphql } from 'src/graphql';

export const UploadImage = graphql(`
  mutation UploadImage($input: NewUploadInput!) {
    createUpload(input: $input) {
      put_file_url
      upload {
        path
        url
      }
    }
  }
`);
